import Switch from '../../../../../components/switch';
import { useToast } from '../../../../../context/toast';
import { ISettingsResponse } from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';

interface IProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const General = ({ settingsData, refetch }: IProps) => {
  const generalData = settingsData?.figur;
  const { mutate, isLoading } = useUpdateSettingsData();
  const { addToast } = useToast();

  const toggleEmail = () => {
    mutate(
      {
        figur: {
          ...generalData,
          emailVerification: !generalData?.emailVerification,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const toggleKyc = () => {
    mutate(
      {
        figur: {
          ...generalData,
          livelinessKYC: !generalData?.livelinessKYC,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const toggleDynamicAccount = () => {
    mutate(
      {
        figur: {
          ...generalData,
          dynamicAccountOnly: !generalData?.dynamicAccountOnly,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const toggleShowKycBanner = () => {
    mutate(
      {
        figur: {
          ...generalData,
          showKycBanner: !generalData?.showKycBanner,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Email Verification</h5>
        <Switch
          checked={generalData?.emailVerification!}
          onCheck={toggleEmail}
          disabled={isLoading}
        />
      </div>
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Liveliness KYC</h5>
        <Switch
          checked={generalData?.livelinessKYC!}
          onCheck={toggleKyc}
          disabled={isLoading}
        />
      </div>
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Dynamic Account Only</h5>
        <Switch
          checked={generalData?.dynamicAccountOnly!}
          onCheck={toggleDynamicAccount}
          disabled={isLoading}
        />
      </div>
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Show KYC Banner</h5>
        <Switch
          checked={generalData?.showKycBanner!}
          onCheck={toggleShowKycBanner}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default General;
